.fa-facebook {
  font-size: 40px;
  color: #fff;
  text-decoration-line: none;
}

.fa-facebook:hover {
  color: #fff;
  transition: 0.3s ease;
}

.fa-instagram {
  font-size: 40px;
  text-decoration-line: none;
  color: #fff;
  margin-left: 20px;
  margin-right: 20px;
}

.fa-instagram:hover {
  color: #fff;
}
