.navbar-light .navbar-nav .nav-link {
  color: #92c83e;
  font-weight: bold;
  padding-right: 10px;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgb(146,200,62)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-toggler {
  color : #92c83e;
}

* {
  font-family: 'kufam';
}

@font-face {
  font-family: 'kufam';
  src: url(../fonts/Tajawal-Medium.ttf);
  font-style: normal;
}
